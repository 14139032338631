const BASE_API = 'https://ims-api-stg.danhkhoi.io';
const UPLOAD_FILE_API = 'https://files-api-stg.danhkhoi.io';

export const environment = {
  production: false,
  baseApi: `${BASE_API}`,
  applicationCode: 'IMS',
  apiUploadFile: `${UPLOAD_FILE_API}`,
  apiKeyTinyMCE: 'xaw6fp7d1lg8zra5clnmow6j5lftadffx06kqx40mazttsec',
};
