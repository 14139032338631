export const PERMISSION_CODE = {
  // NEW FOR IMS
  SYSTEM: {
    IMS_FULL_CONTROL: 'IMS_FULL_CONTROL',
  },
  ACCOUNTS: {
    IMS_ACCOUNTS_VIEW: 'IMS_ACCOUNTS_VIEW',
    IMS_ACCOUNTS_CREATE: 'IMS_ACCOUNTS_CREATE',
    IMS_ACCOUNTS_UPDATE: 'IMS_ACCOUNTS_UPDATE',
    IMS_ACCOUNTS_DELETE: 'IMS_ACCOUNTS_DELETE',
    IMS_ACCOUNTS_LOCK: 'IMS_ACCOUNTS_LOCK',
    IMS_ACCOUNTS_VIEW_DETAIL: 'IMS_ACCOUNTS_VIEW_DETAIL',
    IMS_ACCOUNTS_RESET_PASSWORD: 'IMS_ACCOUNTS_RESET_PASSWORD',
  },
  EMPLOYEES: {
    IMS_EMPLOYEES_VIEW: 'IMS_EMPLOYEES_VIEW',
    IMS_EMPLOYEES_CREATE: 'IMS_EMPLOYEES_CREATE',
    IMS_EMPLOYEES_UPDATE: 'IMS_EMPLOYEES_UPDATE',
    IMS_EMPLOYEES_DELETE: 'IMS_EMPLOYEES_DELETE',
    IMS_EMPLOYEES_VIEW_DETAIL: 'IMS_EMPLOYEES_VIEW_DETAIL',
    IMS_EMPLOYEES_IMPORT: 'IMS_EMPLOYEES_IMPORT',
    IMS_EMPLOYEES_EXPORT_TEMPLATE: 'IMS_EMPLOYEES_EXPORT_TEMPLATE',
  },
  PROFILE: {
    IMS_PROFILE_VIEW: 'IMS_PROFILE_VIEW',
    IMS_PROFILE_CHANGE_PASSWORD: 'IMS_PROFILE_CHANGE_PASSWORD',
    IMS_PROFILE_UPDATE: 'IMS_PROFILE_UPDATE',
  },
  ROLES: {
    IMS_ROLES_CREATE: 'IMS_ROLES_CREATE',
    IMS_ROLES_UPDATE: 'IMS_ROLES_UPDATE',
    IMS_ROLES_DELETE: 'IMS_ROLES_DELETE',
    IMS_ROLES_VIEW: 'IMS_ROLES_VIEW',
    IMS_ROLES_VIEW_DETAIL: 'IMS_ROLES_VIEW_DETAIL',
    IMS_ROLES_CHANGE_ROLE_USER: 'IMS_ROLES_CHANGE_ROLE_USER',
  },
  DEPARTMENTS: {
    IMS_DEPARTMENTS_VIEW: 'IMS_DEPARTMENTS_VIEW',
    IMS_DEPARTMENTS_CREATE: 'IMS_DEPARTMENTS_CREATE',
    IMS_DEPARTMENTS_UPDATE: 'IMS_DEPARTMENTS_UPDATE',
    IMS_DEPARTMENTS_DELETE: 'IMS_DEPARTMENTS_DELETE',
    IMS_DEPARTMENTS_VIEW_DETAIL: 'IMS_DEPARTMENTS_VIEW_DETAIL',
  },
  CONTRACTS: {
    IMS_CONTRACTS_VIEW: 'IMS_CONTRACTS_VIEW',
    IMS_CONTRACTS_CREATE: 'IMS_CONTRACTS_CREATE',
    IMS_CONTRACTS_UPDATE: 'IMS_CONTRACTS_UPDATE',
    IMS_CONTRACTS_DELETE: 'IMS_CONTRACTS_DELETE',
    IMS_CONTRACTS_VIEW_DETAIL: 'IMS_CONTRACTS_VIEW_DETAIL',
  },
  SUPPLIERS: {
    IMS_SUPPLIERS_VIEW: 'IMS_SUPPLIERS_VIEW',
    IMS_SUPPLIERS_CREATE: 'IMS_SUPPLIERS_CREATE',
    IMS_SUPPLIERS_UPDATE: 'IMS_SUPPLIERS_UPDATE',
    IMS_SUPPLIERS_DELETE: 'IMS_SUPPLIERS_DELETE',
    IMS_SUPPLIERS_VIEW_DETAIL: 'IMS_SUPPLIERS_VIEW_DETAIL',
  },
  PROPOSALS: {
    IMS_PROPOSALS_VIEW: 'IMS_PROPOSALS_VIEW',
    IMS_PROPOSALS_CREATE: 'IMS_PROPOSALS_CREATE',
    IMS_PROPOSALS_UPDATE: 'IMS_PROPOSALS_UPDATE',
    IMS_PROPOSALS_DELETE: 'IMS_PROPOSALS_DELETE',
    IMS_PROPOSALS_VIEW_DETAIL: 'IMS_PROPOSALS_VIEW_DETAIL',
    IMS_PROPOSALS_EXPORT: 'IMS_PROPOSALS_EXPORT',
    IMS_PROPOSALS_EXPORT_DETAIL: 'IMS_PROPOSALS_EXPORT_DETAIL',
    IMS_PROPOSALS_APPROVAL: 'IMS_PROPOSALS_APPROVAL',
  },
  PROPOSAL_PAYMENTS: {
    IMS_PROPOSAL_PAYMENTS_VIEW: 'IMS_PROPOSAL_PAYMENTS_VIEW',
    IMS_PROPOSAL_PAYMENTS_CREATE: 'IMS_PROPOSAL_PAYMENTS_CREATE',
    IMS_PROPOSAL_PAYMENTS_UPDATE: 'IMS_PROPOSAL_PAYMENTS_UPDATE',
    IMS_PROPOSAL_PAYMENTS_DELETE: 'IMS_PROPOSAL_PAYMENTS_DELETE',
    IMS_PROPOSAL_PAYMENTS_VIEW_DETAIL: 'IMS_PROPOSAL_PAYMENTS_VIEW_DETAIL',
  },
  COST_TYPES: {
    IMS_COST_TYPES_VIEW: 'IMS_COST_TYPES_VIEW',
    IMS_COST_TYPES_CREATE: 'IMS_COST_TYPES_CREATE',
    IMS_COST_TYPES_UPDATE: 'IMS_COST_TYPES_UPDATE',
    IMS_COST_TYPES_DELETE: 'IMS_COST_TYPES_DELETE',
    IMS_COST_TYPES_VIEW_DETAIL: 'IMS_COST_TYPES_VIEW_DETAIL',
  },
  INVOICES: {
    IMS_INVOICES_VIEW: 'IMS_INVOICES_VIEW',
    IMS_INVOICES_CREATE: 'IMS_INVOICES_CREATE',
    IMS_INVOICES_UPDATE: 'IMS_INVOICES_UPDATE',
    IMS_INVOICES_DELETE: 'IMS_INVOICES_DELETE',
    IMS_INVOICES_VIEW_DETAIL: 'IMS_INVOICES_VIEW_DETAIL',
  },
  PROJECTS: {
    IMS_PROJECTS_VIEW: 'IMS_PROJECTS_VIEW',
    IMS_PROJECTS_CREATE: 'IMS_PROJECTS_CREATE',
    IMS_PROJECTS_UPDATE: 'IMS_PROJECTS_UPDATE',
    IMS_PROJECTS_DELETE: 'IMS_PROJECTS_DELETE',
    IMS_PROJECTS_VIEW_DETAIL: 'IMS_PROJECTS_VIEW_DETAIL',
  },
  FINANCIAL_CODES: {
    IMS_FINANCIAL_CODES_UPDATE: 'IMS_FINANCIAL_CODES_UPDATE',
    IMS_FINANCIAL_CODES_CREATE: 'IMS_FINANCIAL_CODES_CREATE',
    IMS_FINANCIAL_CODES_DELETE: 'IMS_FINANCIAL_CODES_DELETE',
    IMS_FINANCIAL_CODES_VIEW: 'IMS_FINANCIAL_CODES_VIEW',
    IMS_FINANCIAL_CODES_VIEW_DETAIL: 'IMS_FINANCIAL_CODES_VIEW_DETAIL',
  },
  BUDGET_PLANS: {
    IMS_BUDGET_PLANS_UPDATE: 'IMS_BUDGET_PLANS_UPDATE',
    IMS_BUDGET_PLANS_CREATE: 'IMS_BUDGET_PLANS_CREATE',
    IMS_BUDGET_PLANS_DELETE: 'IMS_BUDGET_PLANS_DELETE',
    IMS_BUDGET_PLANS_VIEW: 'IMS_BUDGET_PLANS_VIEW',
    IMS_BUDGET_PLANS_VIEW_DETAIL: 'IMS_BUDGET_PLANS_VIEW_DETAIL',
    IMS_BUDGET_PLANS_EXPORT: 'IMS_BUDGET_PLANS_EXPORT',
  },
  DAILY_REPORTS: {
    IMS_DAILY_REPORTS_VIEW: 'IMS_DAILY_REPORTS_VIEW',
    IMS_DAILY_REPORTS_EXPORT: 'IMS_DAILY_REPORTS_EXPORT',
  },
};

export const ROLE_CODE = {
  SUPERADMIN: {
    code: 'SUPERADMIN',
    name: 'SuperAdmin',
    description: 'Super Admin',
    isActive: true,
  },
};
